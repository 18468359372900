<template>
<div class="insurance-features">
  <b-container class="bv-example-row">
    <h1 v-if="header" class="text-warning text-center pb-3 font-weight-bold">{{header}}</h1>
    <b-row class="mt-3 justify-content-around flex-wrap d-flex">
      <b-col md="6" lg="4" v-for="(feat, index) in features" :key="index">
        <div class="insurance-feature-item text-center">
          <img :src="feat.img" alt="" class="img-fluid img" v-if="feat.img" />
          <h3 class="insurance-feature-item-title fw-bold">{{feat.title}}</h3>
          <p class="insurance-feature-item-desc m-0">{{feat.desc}}</p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
export default {
  props: ['features', 'header'],
  data () {
    return {
      // features: [
      //   // icon: require("@/assets/images/ibbil/insurance/insurance-cover.jpeg")
      //   {
      //     icon: 'img',
      //     title: 'المستهدفين فى التامين',
      //     desc: 'جميع ملاك الإبل بمختلف أنواعها داخل المملكة العربية السعودية ، حيث تستطيع الحصول على تأمين لنقل الإبل داخل المملكة وذلك من خلال تعبئة الوثيقة المتوفرة في الموقع'
      //   },
      //   {
      //     icon: 'img',
      //     title: 'تأمين نقل الإبل',
      //     desc: 'جميع ملاك الإبل بمختلف أنواعها داخل المملكة العربية السعودية ، حيث تستطيع الحصول على تأمين لنقل الإبل داخل المملكة وذلك من خلال تعبئة الوثيقة المتوفرة في الموقع'
      //   },
      //   {
      //     icon: 'img',
      //     title: 'المستهدفين فى التامين',
      //     desc: 'جميع ملاك الإبل بمختلف أنواعها داخل المملكة العربية السعودية ، حيث تستطيع الحصول على تأمين لنقل الإبل داخل المملكة وذلك من خلال تعبئة الوثيقة المتوفرة في الموقع'
      //   },
      //   {
      //     icon: 'img',
      //     title: 'تأمين نقل الإبل',
      //     desc: 'جميع ملاك الإبل بمختلف أنواعها داخل المملكة العربية السعودية ، حيث تستطيع الحصول على تأمين لنقل الإبل داخل المملكة وذلك من خلال تعبئة الوثيقة المتوفرة في الموقع'
      //   },
      //   {
      //     icon: 'img',
      //     title: 'المستهدفين فى التامين',
      //     desc: 'جميع ملاك الإبل بمختلف أنواعها داخل المملكة العربية السعودية ، حيث تستطيع الحصول على تأمين لنقل الإبل داخل المملكة وذلك من خلال تعبئة الوثيقة المتوفرة في الموقع'
      //   },
      //   {
      //     icon: 'img',
      //     title: 'تأمين نقل الإبل',
      //     desc: 'جميع ملاك الإبل بمختلف أنواعها داخل المملكة العربية السعودية ، حيث تستطيع الحصول على تأمين لنقل الإبل داخل المملكة وذلك من خلال تعبئة الوثيقة المتوفرة في الموقع'
      //   }
      // ]
    }
  }
}
</script>

<style lang="scss">
  .insurance-features {
    background-color: #f6f6f6;
    padding: 54px 0;
    .insurance-feature-item {
      padding: 0 20px;
      margin-bottom: 50px;
      .img {
        margin-bottom: 25px;
      }
      .insurance-feature-item-title {
        color: var(--iq-warning);
        margin-bottom: 24px;
        font-weight: bold;
      }
      .insurance-feature-item-desc {
        line-height: 38px;
        font-size: 20px;
        color: #646464;
      }
    }
  }
</style>
