<template>
<div class="insurance-footer">
  <b-container>
    <h2 class="insurance-footer-title text-center">{{title}}</h2>
    <b-row class="justify-content-center">
      <b-col md="8">
        <p class="insurance-footer-desc text-center">{{desc}}</p>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-center">
      <b-button @click="$router.push({name: routerName})" variant="primary" type="button" class="px-5 py-2 iq-border-radius-5 border-0 text-center font-weight-bold insurance-footer-btn">
        {{btnTitle || 'امن الان'}}
      </b-button>
    </div>
  </b-container>
</div>
</template>

<script>
export default {
  props: ['title', 'desc', 'btnTitle', 'routerName']
}
</script>

<style lang="scss">
  .insurance-footer {
    height: 519px;
    background: #D39D45;
    opacity: 1;
    padding: 65px 0;
    .insurance-footer-title {
      font-weight: bold !important;
      font-size: 45px !important;
      padding-bottom: 20px;
      position: relative;
      margin-bottom: 35px !important;
      color: #fff !important;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 119px;
        border: 1px solid #FFFFFF;

      }
    }
    .insurance-footer-desc {
      font-size: 20px;
      color: #fff;
      margin-bottom: 50px;
      line-height: 45px;
    }
    .insurance-footer-btn {
      min-width: 243px;
      height: 50px;
      background-color: #3F6854;
      border-radius: 10px;
      font-size: 20px !important;
      color: #fff !important;
    }
  }
</style>
