<template>
<div class="insurance-how">
  <b-container>

    <landing-how-item
      v-for="(item, index) in items"
      :key="index"
      :title="item.title"
      :desc="item.desc"
      :hasBtn="item.hasBtn"
      :reverse="item.reverse"
      :text="item.text"
      :routeName="item.routeName"
    />
  </b-container>
</div>
</template>

<script>
import landingHowItem from './landingHowItem.vue'
export default {
  props: ['items'],
  components: {
    landingHowItem
  }
}
</script>

<style lang="scss">
.insurance-how {
  background-color: #fff;
  padding: 98px 0;
}
</style>
