<template>
<div class="insurance-how-item">
  <b-row :class="{ 'flex-row-reverse': reverse }" class="align-items-center insurance-how-item-row">
    <b-col lg="5" class="d-flex overflow-hidden" :class="{'justify-content-start justify-content-lg-end': reverse}">
      <img src="@/assets/images/ibbil/insurance/insurance-cover.jpeg" alt="" class="insurance-how-item-img" />
    </b-col>
    <b-col lg="6">
      <div class="insurance-how-item-content">
        <h3 class="insurance-how-item-title fw-bold">{{title}}</h3>
        <p class="insurance-how-item-desc m-0">{{desc}}</p>
        <div class="d-flex justify-content-end btn-wrapper"  v-if="hasBtn">
          <b-button type="button" variant="warning" @click="$router.push({name: routeName})" class="px-5 py-2 iq-border-radius-5 text-center font-weight-bold btn border-0">
           {{ text ? text : 'اشترك الان' }}
          </b-button>
        </div>
      </div>
    </b-col>
  </b-row>
</div>
</template>

<script>
export default {
  props: ['title', 'desc', 'hasBtn', 'reverse', 'text', 'routeName']
}
</script>

<style lang="scss">
  .insurance-how-item-row {
    justify-content: start !important;
  }
  .insurance-how-item {
    margin-bottom: 100px;
    .insurance-how-item-img {
      max-width: 480px;
      border-radius: 21px;
      object-fit: cover;
    }
    .insurance-how-item-title {
      color: #646464 !important;
      margin-bottom: 23px !important;
      font-weight: bold !important;
      font-size: 30px !important;
      @media (max-width: 991px) {
        & {
          margin-top: 20px !important;
        }
      }
    }
    .insurance-how-item-desc {
      font-size: 20px;
      color: #646464;
      line-height: 38px;
    }
    .btn-wrapper {
      margin-top: 38px;
      .btn {
        background: #D39D45D8;
        color: #fff;
        min-width: 198px;
        height: 50px;
        border-radius: 14px;
        font-size: 20px;
      }
    }
  }
</style>
