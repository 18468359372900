<template>
  <div class="insurance-about">
    <b-container>
      <b-row class="no-gutters align-items-top">
        <b-col lg="5">
          <div class="insurance-img">
            <img :src="img" alt="insurance img" class="img-fluid" />
          </div>
        </b-col>
        <b-col lg="7">
          <div class="insurance-desc-wrapper">
            <p class="insurance-desc">{{details}}</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  props: ['img', 'details']
}
</script>

<style lang="scss">
  .insurance-about {
    background-color: #fff;
    padding: 54px 0;
    .insurance-img {
      img {
        border-radius: 10px;
      }
    }
    .insurance-desc-wrapper {
      padding-inline-start: 63px;
      @media(max-width: 991px) {
        & {
          padding-inline-start: 0;
          margin-top: 20px;
        }
      }
      .insurance-desc {
        font-size: 20px;
        color: #646464;
        line-height: 38px;
      }
    }
  }
</style>
