<template>
 <div class="cover-insurance" :style="{ 'background-image': 'url(' + coverImage + ')' }">
    <div class="overlay-cover d-flex align-items-center" :style="{backgroundColor: overlayColor ? overlayColor : '#808080d1'}">
      <b-container>
        <div class="content-insurance d-flex justify-content-between align-items-center">
          <div class="info w-75">
<!--            <img :src="require('@/assets/images/ibbil/logo.svg')" alt="ibbil" v-if="logo">-->
            <h2 class="text-white font-weight-bold mt-5 font-size-60" v-text="title"></h2>
            <h3 class="text-white mt-3 font-size-30" v-text="subtitle"></h3>
            <p  class="text-white mt-3 w-75 font-size-23" v-text="desc" v-if="desc"></p>
            <div class="actions mt-4">
              <router-link :to="{name: routerName}" ><b-button variant="warning" class="text-white px-4 py-2 font-size-20 ml-4">{{btnTitle}}</b-button></router-link>
              <router-link :to="{name: routerAboutName}" class="link-privacy">{{$t('insurance.termsAndDetails')}}</router-link>
            </div>
          </div>
          <div class="insuranseLogo w-25 d-flex justify-content-center align-items-center" v-if="otherLogo">
            <img :src="require('@/assets/images/ibbil/insurance/amenha.png')" class="w-75" alt="logo"/>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  props: ['coverImage', 'overlayColor', 'logo', 'title', 'subtitle', 'desc', 'routerName', 'routerAboutName', 'btnTitle', 'otherLogo']
}
</script>

<style>
.cover-insurance{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.overlay-cover {
  width: 100%;
  min-height: 410px;
  background: 0% 0% no-repeat padding-box;
}
.w-250 {
  width: 200px !important;
}
.link-privacy {
  color: #FFD89A !important;
  font-size: 20px;
  text-decoration: underline !important;
}
</style>
