<template>
<div>
  <landingCover
    :coverImage="coverImage"
    logo=true
    :title="$t('insurance.firstInsure')"
    :subtitle="$t('insurance.nowYouCanInsure')"
    routerName="registerInsurance"
    :btnTitle="$t('insurance.insureNow')"
    otherLogo=true
    routerAboutName="aboutInsurance"
  />
  <landing-details
    :img="landingDetailsImage"
    :details="$t('insurance.insureDetails')"
  />
  <landing-features :features="features" />
  <landing-how :items="items" />
  <landing-footer
      routeName="registerInsurance"
      :title="$t('insurance.CamelTransportInsurance')"
      :desc="$t('insurance.CamelTransportInsuranceDesc')"
      :btn-title="$t('insurance.insureNow')"

  />
</div>
</template>
<script>
import { core } from '@/config/pluginInit'
import landingCover from '../../mainComponentsLandingPage/landingCover.vue'
import landingDetails from '../../mainComponentsLandingPage/landingDetails.vue'
import landingFeatures from '../../mainComponentsLandingPage/landingFeatures.vue'
import landingHow from '../../mainComponentsLandingPage/landingHow.vue'
import landingFooter from '../../mainComponentsLandingPage/landingFooter.vue'

export default {
  components: {
    landingCover,
    landingDetails,
    landingFeatures,
    landingHow,
    landingFooter
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      coverImage: require('@/assets/images/ibbil/insurance/insurance-cover.jpeg'),
      landingDetailsImage: require('@/assets/images/ibbil/insurance/insurance-cover.jpeg')
      // features: [
      //   {
      //     icon: 'img',
      //     title: this.$t('insurance.InsuranceTargetAudience'),
      //     desc: this.$t('insurance.InsuranceTargetAudienceInfo')
      //   },
      //   {
      //     icon: 'img',
      //     title: this.$t('insurance.CamelTransportInsurance'),
      //     desc: this.$t('insurance.CamelTransportInsuranceInfo')
      //   },
      //   {
      //     icon: 'img',
      //     title: this.$t('insurance.ChooseTheRightInsuranceForYou'),
      //     desc: this.$t('insurance.ChooseTheRightInsuranceForYouInfo')
      //   }
      // ],
      // items: [
      //   {
      //     title: this.$t('insurance.howToInsure'),
      //     desc: this.$t('insurance.howToInsureInfo'),
      //     hasBtn: true,
      //     reverse: false
      //   },
      //   {
      //     title: this.$t('insurance.howToPay'),
      //     desc: this.$t('insurance.howToPayInfo'),
      //     hasBtn: false,
      //     reverse: true
      //   }
      // ]
    }
  },
  computed: {
    features () {
      return [
        {
          icon: 'img',
          title: this.$t('insurance.InsuranceTargetAudience'),
          desc: this.$t('insurance.InsuranceTargetAudienceInfo')
        },
        {
          icon: 'img',
          title: this.$t('insurance.CamelTransportInsurance'),
          desc: this.$t('insurance.CamelTransportInsuranceInfo')
        },
        {
          icon: 'img',
          title: this.$t('insurance.ChooseTheRightInsuranceForYou'),
          desc: this.$t('insurance.ChooseTheRightInsuranceForYouInfo')
        }
      ]
    },
    items () {
      return [
        {
          title: this.$t('insurance.howToInsure'),
          desc: this.$t('insurance.howToInsureInfo'),
          hasBtn: true,
          text: this.$t('insurance.insureNow'),
          routeName: 'registerInsurance',
          reverse: false
        },
        {
          title: this.$t('insurance.howToPay'),
          desc: this.$t('insurance.howToPayInfo'),
          hasBtn: false,
          reverse: true,
          routeName: 'registerInsurance'
        }
      ]
    }
  }
}
</script>
